* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #edf1fd;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activePath {
  padding: 5px 25px;
  background: white;
  color: #363b95;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
}

.notActivePath {
  color: white;
  padding: 5px 25px;
  font-weight: bolder;
}

/* ANTD STYLES */

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.anticon {
  vertical-align: 4px !important;
}

.ant-ribbon {
  top: -19px !important;
  left: -15px !important;
}
